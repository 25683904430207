<template>
  <services-list
    @selected="selected"
  />
</template>

<script>
import ServicesList from '../../site/services/ServicesList.vue'

export default {
  components: {
    ServicesList,
  },
  data() {
    return {
      selectedServices: [],
    }
  },
  methods: {
    handleSubmit() {
      return this.selectedServices.length !== 0
    },
    selected(services) {
      this.selectedServices = services
    },
  },
}
</script>
