<template>
  <b-overlay
    variant="white"
    :show="showLoading"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
  >
    <div class="h2 mb-4">
      <span id="popover-target-1">
        Выберите бренды обслуживаемых автомобилей
      </span>
    </div>

    <b-popover
      target="popover-target-1"
      triggers="click"
      placement="left"
      :show.sync="popoverShow"
    >
      <template #title>
        Выберите бренды авто
      </template>
      <p class="mb-1">
        Бренды и модели авто, которые обслуживает ваш сервис.
        Вы сможете вернуться к выбору в любой момент
      </p>
      <div class="text-right">
        <b-button
          variant="outline-primary"
          size="sm"
          @click.prevent="onOkPopoverShow"
        >
          Ок, понятно
        </b-button>
      </div>
    </b-popover>

    <div class="row mb-3 align-items-end">
      <div class="col-12 col-md-6 col-lg-4">
        <label
          for="search"
        >
          Поиск:
        </label>
        <b-form-input
          id="search"
          v-model="search"
        />
      </div>
      <div class="col-12 col-md-6 col-lg-8 d-md-flex justify-content-end">
        <b-button
          class="d-flex align-items-center mr-1 my-1 my-md-0"
          variant="primary"
          :disabled="generationRunning ? true : false"
          @click="onGenerate"
        >
          <template v-if="generationRunning">
            Идет генерация страниц
            <feather-icon
              icon="LoaderIcon"
              size="18"
              class="ml-1"
            />
          </template>
          <template v-else>
            <feather-icon
              icon="RepeatIcon"
              size="18"
              class="mr-1"
            />
            Сгенерировать страницы
          </template>
        </b-button>
        <b-button
          v-if="$route.name !== 'init'"
          class="d-inline-flex align-items-center"
          variant="primary"
          :to="{name: 'site_brands_and_models_sort'}"
        >
          <feather-icon
            icon="GridIcon"
            size="18"
            class="mr-1"
          />
          Сортировка
        </b-button>
      </div>
    </div>
    <div
      v-if="popularBrands.length"
      class="mb-4"
    >
      <div class="h3 mb-2">
        Популярные бренды
      </div>
      <div class="">
        <div class="accordion-label__content">
          <div class="row align-items-center w-100">
            <div class="col-12 col-lg-4">
              Название бренда
            </div>
            <div class="col-12 col-lg-4">
              Название по-русски
            </div>
            <div class="col-12 col-lg-4">
              Slug
            </div>
          </div>
        </div>
      </div>
      <div
        class="accordion"
        role="tablist"
      >
        <div
          v-for="brand in popularBrands"
          :key="brand.id"
          class="accordion__item"
        >
          <div
            v-b-toggle="`accordion-${brand.slug}`"
            role="tab"
            class="accordion-label"
          >
            <div class="accordion-label__content">
              <div class="row align-items-center w-100">
                <div class="col-12 col-lg-4">
                  <div class="d-flex align-items-center">
                    <div
                      class="d-flex align-items-center mr-2"
                      @click.stop
                    >
                      <b-form-checkbox
                        :checked="selectedBrands.some(item => item.brandSlug === brand.slug)"
                        :indeterminate="selectedBrands.some(item => item.brandSlug === brand.slug && !item.AllSelectedModels)"
                        @change="toggleCheckBrand(brand.slug)"
                      />
                    </div>
                    <div class="accordion-label__icon">
                      <img
                        v-if="brand.logo_sm"
                        :src="brand.logo_sm.url"
                        alt="логотип"
                      >
                      <div
                        v-else
                        class="accordion-label__icon-cover"
                      />
                    </div>
                    <span class="d-flex align-items-center">{{ brand.name }}</span>
                  </div>
                </div>
                <div class="col-12 col-lg-4">
                  {{ brand.name_rus || '—' }}
                </div>
                <div class="col-12 col-lg-4">
                  <code>{{ brand.slug }}</code>
                </div>
              </div>
            </div>
          </div>
          <b-collapse
            :id="`accordion-${brand.slug}`"
            accordion="my-accordion"
            role="tabpanel"
            @show="currentBrandSlug = brand.slug"
          >
            <models
              v-if="currentBrandSlug === brand.slug"
              :key="currentBrandSlug"
              :brand-slug="currentBrandSlug"
              :brand-is-selected="selectedBrands.some(item => item.brandSlug === brand.slug)"
              @changeModels="changeModels"
              @selectedModel="selectBrandsWithoutModels"
            />
          </b-collapse>
        </div>
      </div>
    </div>
    <div
      v-if="isNotPopularBrands.length"
    >
      <div class="h3 mb-2">
        Остальные бренды
      </div>
      <div class="">
        <div class="accordion-label__content">
          <div class="row align-items-center w-100">
            <div class="col-12 col-lg-4">
              Название бренда
            </div>
            <div class="col-12 col-lg-4">
              Название по-русски
            </div>
            <div class="col-12 col-lg-4">
              Slug
            </div>
          </div>
        </div>
      </div>
      <div
        class="accordion"
        role="tablist"
      >
        <div
          v-for="brand in isNotPopularBrands"
          :key="brand.id"
          class="accordion__item"
        >
          <div
            v-b-toggle="`accordion-${brand.slug}`"
            role="tab"
            class="accordion-label"
          >
            <div class="accordion-label__content">
              <div class="row align-items-center w-100">
                <div class="col-12 col-lg-4">
                  <div class="d-flex align-items-center">
                    <div
                      class="d-flex align-items-center mr-2"
                      @click.stop
                    >
                      <b-form-checkbox
                        :checked="selectedBrands.some(item => item.brandSlug === brand.slug)"
                        :indeterminate="selectedBrands.some(item => item.brandSlug === brand.slug && !item.AllSelectedModels)"
                        @change="toggleCheckBrand(brand.slug)"
                      />
                    </div>
                    <div class="accordion-label__icon">
                      <img
                        v-if="brand.logo_sm"
                        :src="brand.logo_sm.url"
                        alt="логотип"
                      >
                      <div
                        v-else
                        class="accordion-label__icon-cover"
                      />
                    </div>
                    <span class="d-flex align-items-center">{{ brand.name }}</span>
                  </div>
                </div>
                <div class="col-12 col-lg-4">
                  {{ brand.name_rus || '—' }}
                </div>
                <div class="col-12 col-lg-4">
                  <code>{{ brand.slug }}</code>
                </div>
              </div>
            </div>
          </div>
          <b-collapse
            :id="`accordion-${brand.slug}`"
            accordion="my-accordion"
            role="tabpanel"
            @show="currentBrandSlug = brand.slug"
          >
            <models
              v-if="currentBrandSlug === brand.slug"
              :key="currentBrandSlug"
              :brand-slug="currentBrandSlug"
              :brand-is-selected="selectedBrands.some(item => item.brandSlug === brand.slug)"
              @changeModels="changeModels"
              @selectedModel="selectBrandsWithoutModels"
            />
          </b-collapse>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import {
  BOverlay, BFormCheckbox, BCollapse, BFormInput, BPopover, BButton, VBToggle, VBPopover,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import mixinErrorsParse from '@core/mixins/ui/mixinErrorsParse'
import SiteBrand from '@/api/http/models/site/brand/SiteBrand'
import Site from '@/api/http/models/site/site/Site'
import { mapGetters } from 'vuex'
import Models from './Models.vue'

export default {
  components: {
    BOverlay,
    BFormCheckbox,
    BCollapse,
    BFormInput,
    BPopover,
    BButton,
    Models,
  },
  directives: {
    'b-toggle': VBToggle,
    'b-popover': VBPopover,
  },
  mixins: [mixinErrorsParse],
  data() {
    return {
      brands: [],
      currentBrandSlug: null,
      selectedBrands: [],
      indeterminateBrands: [],
      showLoading: true,
      search: '',
      popoverShow: false,
      generationRunning: false,
    }
  },
  computed: {
    ...mapGetters({
      currentSite: 'user/currentSite',
    }),
    searchedBrands() {
      return this.brands.filter(brand => {
        const searchValue = this.search.toLowerCase().trim()
        const name = brand.name && brand.name.toLowerCase().indexOf(searchValue) !== -1
        const nameRus = brand.name_rus && brand.name_rus.toLowerCase().indexOf(searchValue) !== -1
        const slug = brand.slug && brand.slug.toLowerCase().indexOf(searchValue) !== -1
        return name || nameRus || slug
      })
    },
    popularBrands() {
      return this.searchedBrands.filter(brand => brand.is_popular)
    },
    isNotPopularBrands() {
      return this.searchedBrands.filter(brand => !brand.is_popular)
    },
  },
  async created() {
    const response = await SiteBrand.getAll(this.currentSite.slug)

    if (response.data) {
      this.selectedBrands = response.data.filter(brand => brand.is_selected).map(brand => ({
        brandSlug: brand.slug,
        AllSelectedModels: brand.available_models === brand.selected_models,
      }))
      this.brands = response.data
      this.showLoading = false
      this.popoverShow = !localStorage.brandsPopover
      this.$emit('selected', this.selectedBrands)
    }
  },
  mounted() {
    if (this.currentSite.generation_pages_running) {
      this.generationRunning = true
    } else {
      this.generationRunning = false
    }
  },
  methods: {
    onOkPopoverShow() {
      localStorage.brandsPopover = true
      this.popoverShow = false
    },
    async toggleCheckBrand(brandSlug) {
      const index = this.selectedBrands.findIndex(item => item.brandSlug === brandSlug)
      if (index !== -1) {
        const response = await SiteBrand.unselect(this.currentSite.slug, brandSlug)
        if (response.status === 'success') {
          this.selectedBrands.splice(index, 1)
        }
      } else {
        const response = await SiteBrand.select(this.currentSite.slug, brandSlug, true)
        if (response.status === 'success') {
          this.selectedBrands.push({
            brandSlug,
            AllSelectedModels: true,
          })
        }
      }
      this.$emit('selected', this.selectedBrands)
    },
    changeModels(value) {
      const index = this.selectedBrands.findIndex(item => item.brandSlug === value.brandSlug)
      this.selectedBrands[index].AllSelectedModels = value.modelsAllChecked
    },
    async selectBrandsWithoutModels(brandSlug, done) {
      const response = await SiteBrand.select(this.currentSite.slug, brandSlug, false)
      if (response.status === 'success') {
        this.selectedBrands.push({
          brandSlug,
          AllSelectedModels: false,
        })
      }
      await done()
    },
    async onGenerate() {
      const responseGenerate = await Site.generate(this.currentSite.slug)

      if (responseGenerate.status === 'success') {
        this.generationRunning = true
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Генерация страниц запущена',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      } else if (responseGenerate.errors) {
        this.mixinErrorsParse(responseGenerate.errors)
      }
    },
  },
}
</script>
