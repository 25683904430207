<template>
  <div class="py-3 pl-3">
    <b-overlay
      :show="showLoading"
      variant="white"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
      rounded="sm"
    >
      <div v-if="!showLoading">
        <div v-if="models.length">
          <div
            v-if="popularModels.length"
            class="mb-4"
          >
            <div class="h4 mb-2">
              Популярные модели
            </div>
            <div class="">
              <div class="accordion-label__content">
                <div class="row align-items-center w-100">
                  <div class="col-12 col-lg-4">
                    Название модели
                  </div>
                  <div class="col-12 col-lg-4">
                    Название по-русски
                  </div>
                  <div class="col-12 col-lg-4">
                    Slug
                  </div>
                </div>
              </div>
            </div>
            <div
              class="accordion"
              role="tablist"
            >
              <div
                v-for="model in popularModels"
                :key="model.slug"
                class="accordion__item"
              >
                <div class="accordion-label accordion-label--disable-arrow">
                  <div class="accordion-label__content">
                    <div class="row align-items-center w-100">
                      <div class="col-12 col-lg-4">
                        <div class="d-flex align-items-center">
                          <div
                            class="d-flex align-items-center mr-2"
                            @click.stop
                          >
                            <b-form-checkbox
                              :checked="selectedModels.includes(model.slug)"
                              @change="toggleCheckModel(model.slug)"
                            />
                          </div>
                          🇬🇧 {{ model.name }}
                        </div>
                      </div>
                      <div class="col-12 col-lg-4">
                        🇷🇺 {{ model.name_rus || '—' }}
                      </div>
                      <div class="col-12 col-lg-4">
                        <code>{{ model.slug }}</code>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="isNotPopularModels.length">
            <div class="h4 mb-2">
              Модели
            </div>
            <div class="">
              <div class="accordion-label__content">
                <div class="row align-items-center w-100">
                  <div class="col-12 col-lg-4">
                    Название модели
                  </div>
                  <div class="col-12 col-lg-4">
                    Название по-русски
                  </div>
                  <div class="col-12 col-lg-4">
                    Slug
                  </div>
                </div>
              </div>
            </div>
            <div
              class="accordion"
              role="tablist"
            >
              <div
                v-for="model in isNotPopularModels"
                :key="model.slug"
                class="accordion__item"
              >
                <div class="accordion-label accordion-label--disable-arrow">
                  <div class="accordion-label__content">
                    <div class="row align-items-center w-100">
                      <div class="col-12 col-lg-4">
                        <div class="d-flex align-items-center">
                          <div
                            class="d-flex align-items-center mr-2"
                            @click.stop
                          >
                            <b-form-checkbox
                              :checked="selectedModels.includes(model.slug)"
                              @change="toggleCheckModel(model.slug)"
                            />
                          </div>
                          🇬🇧 {{ model.name }}
                        </div>
                      </div>
                      <div class="col-12 col-lg-4">
                        🇷🇺 {{ model.name_rus || '—' }}
                      </div>
                      <div class="col-12 col-lg-4">
                        <code>{{ model.slug }}</code>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          У этого бренда пока нет моделей
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import { BOverlay, BFormCheckbox } from 'bootstrap-vue'
import SiteModel from '@/api/http/models/site/model/SiteModel'
import { mapGetters } from 'vuex'

export default {
  components: {
    BOverlay,
    BFormCheckbox,
  },
  props: {
    brandSlug: {
      type: String,
      required: true,
    },
    brandIsSelected: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showLoading: true,
      models: [],
      selectedModels: [],
    }
  },
  computed: {
    ...mapGetters({
      currentSite: 'user/currentSite',
    }),
    popularModels() {
      return this.models.filter(model => model.is_popular)
    },
    isNotPopularModels() {
      return this.models.filter(model => !model.is_popular)
    },
  },
  watch: {
    brandIsSelected: {
      handler() {
        this.initial()
      },
    },
  },
  async created() {
    await this.initial()
  },
  methods: {
    async initial() {
      this.showLoading = true
      const response = await SiteModel.getAll(this.currentSite.slug, this.brandSlug)

      if (response.data) {
        this.selectedModels = response.data.filter(model => model.is_selected).map(model => model.slug)
        this.models = await response.data
      }
      this.showLoading = false
    },
    async toggleCheckModel(slug) {
      const modelSlug = slug.toString()
      const index = this.selectedModels.findIndex(a => a === modelSlug)
      if (index !== -1) {
        if (await this.unselectModel(modelSlug)) {
          this.selectedModels.splice(index, 1)
        }
        this.emitChangeModels()
      } else if (this.brandIsSelected) {
        if (await this.selectModel(modelSlug)) {
          this.selectedModels.push(modelSlug)
        }
        this.emitChangeModels()
      } else {
        this.$emit('selectedModel', this.brandSlug, async () => {
          if (await this.selectModel(modelSlug)) {
            this.selectedModels.push(modelSlug)
          }
          this.emitChangeModels()
        })
      }
    },
    async selectModel(modelSlug) {
      const response = await SiteModel.select(this.currentSite.slug, this.brandSlug, modelSlug)
      return response.status === 'success'
    },
    async unselectModel(modelSlug) {
      const response = await SiteModel.unselect(this.currentSite.slug, this.brandSlug, modelSlug)
      return response.status === 'success'
    },
    emitChangeModels() {
      this.$emit('changeModels', {
        brandSlug: this.brandSlug,
        modelsAllChecked: this.selectedModels.length === this.models.length,
      })
    },
  },
}
</script>
