<template>
  <brands-list
    @selected="selected"
  />
</template>

<script>
import BrandsList from '../../site/brands/BrandsList.vue'

export default {
  components: {
    BrandsList,
  },
  data() {
    return {
      selectedBrands: [],
    }
  },
  methods: {
    handleSubmit() {
      return this.selectedBrands.length !== 0
    },
    selected(brands) {
      this.selectedBrands = brands
    },
  },
}
</script>
