<template>
  <div class="py-4">
    <div class="w-100 col-lg-5 ml-auto mr-auto text-center">
      <div class="mb-1">
        <svg
          width="80"
          height="80"
          viewBox="0 0 80 80"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M76.6663 40V37C76.6663 35 75.333 33.6667 73.333 33.6667C71.333 33.6667 69.9997 35 69.9997 37V40C69.9997 56.6667 56.6663 70 39.9997 70C23.333 70 9.99967 56.6667 9.99967 40C9.99967 23.3333 23.333 10 39.9997 10C44.333 10 48.333 11 52.333 12.6667C53.9997 13.3333 55.9997 12.6667 56.6663 11C57.333 9.33333 56.6663 7.33334 54.9997 6.66667C50.333 4.33334 44.9997 3.33334 39.9997 3.33334C19.6663 3.33334 3.33301 19.6667 3.33301 40C3.33301 60.3333 19.6663 76.6667 39.9997 76.6667C60.333 76.6667 76.6663 60.3333 76.6663 40ZM74.333 7.66667C75.6663 6.33334 77.6663 6.33334 78.9997 7.66667C80.333 9 80.333 11 78.9997 12.3333L42.333 49C41.6663 49.6667 40.6663 50 39.9997 50C39.333 50 38.333 49.6667 37.6663 49L27.6663 39C26.333 37.6667 26.333 35.6667 27.6663 34.3333C28.9997 33 30.9997 33 32.333 34.3333L39.9997 42L74.333 7.66667Z"
            fill="black"
          />
          <mask
            id="mask0"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x="3"
            y="3"
            width="77"
            height="74"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M76.6663 40V37C76.6663 35 75.333 33.6667 73.333 33.6667C71.333 33.6667 69.9997 35 69.9997 37V40C69.9997 56.6667 56.6663 70 39.9997 70C23.333 70 9.99967 56.6667 9.99967 40C9.99967 23.3333 23.333 10 39.9997 10C44.333 10 48.333 11 52.333 12.6667C53.9997 13.3333 55.9997 12.6667 56.6663 11C57.333 9.33333 56.6663 7.33334 54.9997 6.66667C50.333 4.33334 44.9997 3.33334 39.9997 3.33334C19.6663 3.33334 3.33301 19.6667 3.33301 40C3.33301 60.3333 19.6663 76.6667 39.9997 76.6667C60.333 76.6667 76.6663 60.3333 76.6663 40ZM74.333 7.66667C75.6663 6.33334 77.6663 6.33334 78.9997 7.66667C80.333 9 80.333 11 78.9997 12.3333L42.333 49C41.6663 49.6667 40.6663 50 39.9997 50C39.333 50 38.333 49.6667 37.6663 49L27.6663 39C26.333 37.6667 26.333 35.6667 27.6663 34.3333C28.9997 33 30.9997 33 32.333 34.3333L39.9997 42L74.333 7.66667Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask0)">
            <rect
              width="80"
              height="80"
              fill="#28C76F"
            />
          </g>
        </svg>
      </div>
      <div class="h2">
        Вы успешно зарегистрировались
      </div>
      <b-card-text class="mb-5">
        Осталось три шага до запуска сайта
      </b-card-text>
      <b-form
        ref="form"
        @submit.stop.prevent="handleSubmit"
      >
        <b-form-group
          class="mb-2"
          label="Укажите название вашего сайта"
          label-for="name"
        >
          <b-form-input
            id="name"
            v-model="site.name"
            v-validate="'required|min:4'"
            name="name"
          />
          <small
            v-show="errors.has('name')"
            class="text-danger"
          >{{ errors.first('name') }}</small>
        </b-form-group>
        <b-form-group
          class="mb-2"
          label="Домен сайта"
          label-for="domain"
        >
          <b-form-input
            id="domain"
            v-model="site.domain"
            v-validate="'required|url'"
            name="domain"
          />
          <small
            v-show="errors.has('domain')"
            class="text-danger"
          >{{ errors.first('domain') }}</small>
        </b-form-group>
      </b-form>
    </div>
  </div>
</template>

<script>
import {
  BCardText, BForm, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import Site from '@/api/http/models/site/site/Site'
import { mapGetters, mapMutations } from 'vuex'

export default {
  components: {
    BForm,
    BCardText,
    BFormGroup,
    BFormInput,
  },
  data() {
    return {
      site: {
        name: '',
        domain: '',
      },
    }
  },
  computed: {
    ...mapGetters({
      userHasSite: 'user/hasSite',
      userSite: 'user/currentSite',
    }),
  },
  async created() {
    if (this.userHasSite) {
      const response = await Site.getOne(this.userSite.slug)

      if (response.data) {
        this.site = response.data
      }
    }
  },
  methods: {
    ...mapMutations({
      updateCurrentSite: 'user/UPDATE_CURRENT_SITE',
    }),
    async handleSubmit() {
      if (!this.site.domain.match(/.{2,}\./gi)) {
        this.errors.add({
          field: 'domain',
          msg: 'Введите корректный домен',
        })

        return false
      }

      const validate = await this.$validator.validateAll()
      if (validate) {
        let response
        if (this.userHasSite) {
          response = await Site.update(this.userSite.slug, this.site)
        } else {
          response = await Site.create({
            name: this.site.name,
            domain: this.site.domain,
          })
        }

        if (response.status === 'success' && response.data) {
          this.updateCurrentSite(response.data)
          return true
        }

        return false
      }

      return false
    },
  },
}
</script>
